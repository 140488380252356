import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthorizedRoute } from "./components/AuthorizedRoute";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Loading } from "./components/Loading";
import { ENV, IS_LOCAL, SENTRY_DSN } from "./constants";
import {
  ERROR_404_URL,
  ERROR_500_URL,
  TOKEN_EMBED_URL,
  LOGIN_URL,
  PASSWORD_RESET_URL,
  ROOT_URL,
  CREDENTIALS_EMBED_URL,
  TOKEN_INTERACT_URL,
  CREDENTIALS_INTERACT_URL,
} from "./constants/urls";

import "./App.scss";
import "./css/interflexion.css";

// Sentry
Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: IS_LOCAL ? 0 : 1.0,
  sampleRate: IS_LOCAL ? 0 : 1.0,
  environment: ENV,
});

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const PasswordReset = React.lazy(() => import("./views/Pages/PasswordReset/PasswordReset"));
// const TokenEmbedPage = React.lazy(() => import("./views/Pages/Embed/Token"));
// const CredentialsEmbedPage = React.lazy(() => import("./views/Pages/Embed/Credentials"));
const EmbedPage = React.lazy(() => import("./views/Pages/Embed"));

function App() {
  return (
    console.log("App.tsx"),
    <ErrorBoundary>
      <BrowserRouter>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={LOGIN_URL} component={Login} />
            <Route exact path={PASSWORD_RESET_URL} component={PasswordReset} />
            <Route exact path={ERROR_404_URL} component={Page404} />
            <Route exact path={ERROR_500_URL} component={Page500} />
            <AuthorizedRoute path={ROOT_URL}>
              <Route exact path={TOKEN_EMBED_URL} component={EmbedPage} />
              <Route exact path={CREDENTIALS_EMBED_URL} component={EmbedPage} />
              <Route exact path={TOKEN_INTERACT_URL} component={EmbedPage} />
              <Route exact path={CREDENTIALS_INTERACT_URL} component={EmbedPage} />
              <DefaultLayout />
            </AuthorizedRoute>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
 
export default App;
